import React, { useState } from 'react';
import Layout from '../components/layout';

import TransitionLink from 'gatsby-plugin-transition-link';
import Link from 'gatsby-plugin-transition-link';

import BrandPattern from '../images/brand-pattern.svg';
import LeftChevron from '../images/left-chevron.svg';

import {
  FadeIn,
  FadeInTop,
  FadeInLeft,
  FadeInRight,
  Reveal,
  Timeline,
} from '../components/animation';

// TODO: refactor this to be more reusable and move to
// a separate component file
const AccordionSection = ({ label, children, onClick, isOpen, index }) => (
  <>
    <div className="collapsible">
      <Timeline>
        <FadeInLeft position="<=0.5">
          <div
            className={`link-wrapper `}
            onClick={() => onClick(index)}
            role="link"
            tabIndex={0}
          >
            <img
              className={`chevron ${isOpen ? 'chevron-open' : ''}`}
              alt="chevron"
              src={LeftChevron}
            />
            <span class="label-wrap">
              <h3 class={`label ${isOpen ? 'selected' : ''}`}>{label}</h3>
              <i className="hover-line"></i>
            </span>
          </div>
        </FadeInLeft>
      </Timeline>{' '}
      <div className={`accordion-content ${isOpen ? 'section-visible' : ''}`}>
        {children}
      </div>
    </div>
  </>
);

const Accordion = ({ children }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else setOpenIndex(index);
  };

  return (
    <div className="accordion">
      {children.map((child) => (
        <AccordionSection
          label={child.props.label}
          onClick={handleClick}
          isOpen={openIndex === child.props.index ? true : false}
          index={child.props.index}
        >
          {child.props.children}
        </AccordionSection>
      ))}
    </div>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <div className="hero with-brand-pattern">
        <div className="container">
          <div className="hero-content">
            <Reveal>
              <FadeInTop>
                <h1>
                  <em>Unlock the value</em> of your <br /> vehicle without
                  having
                  <br /> to sell it
                </h1>
              </FadeInTop>
              <FadeInTop position="<=0.5">
                <button className="intro-btn">
                  <Link to="/borrow-now">Apply now</Link>
                </button>
              </FadeInTop>
            </Reveal>
          </div>
        </div>
        <Link to="/">
          <img
            className="brand-pattern"
            src={BrandPattern}
            alt="credup pattern"
          />
        </Link>
      </div>
      <div className="intro with-brand-pattern">
        <div className="container">
          <Reveal>
            <FadeInTop>
              <h1>
                We go beyond the scope of conventional or traditional
                loan-financing to allow for better and more convenient access to
                credit using valued assets owned by people or businesses.
              </h1>
            </FadeInTop>
            <FadeInRight position="<=0.5">
              <TransitionLink to="about" className="cred-link">
                Learn More
                <i className="hover-line"></i>
              </TransitionLink>
            </FadeInRight>
          </Reveal>
        </div>
        <img className="brand-pattern" src={BrandPattern} alt="credup" />
      </div>
      <div className="what-we-offer">
        <div className="container">
          <Reveal>
            <FadeInTop>
              <h1>What we offer</h1>
            </FadeInTop>
          </Reveal>
          <Accordion>
            <div label="Payday Loans" index={0}>
              <div class="items-grid">
                <div class="item">
                  <h4>Asset pawning</h4>
                  <p>
                    Unlock the value of your vehicle without having to sell it
                    through a secured short term loan. Get cash loans for all
                    assets that are movable.
                  </p>
                </div>
                <div class="item">
                  <h4>Payday loans</h4>
                  <p>
                    Don’t let important things go unattended to because of cash,
                    get a loan and payback on your next pay day.
                  </p>
                </div>
                <div class="item">
                  <h4>Salary advance</h4>
                  <p>
                    Get up to X% of your monthly salary upfront so you can pay
                    the tuition fees for your children and things that matter to
                    you.
                  </p>
                </div>
                <div class="item">
                  <h4>Term loans</h4>
                  <p>
                    Need a quick loan? No worries, get a loan and pay back on
                    your term.{' '}
                  </p>
                </div>
                <div class="item">
                  <h4>Asset finance and Leases</h4>
                  <p>
                    Unlock the value of your vehicle without having to sell it
                    through a secured short term loan. Get cash loans for all
                    assets that are movable.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div label="Business Loans" index={1}>
              <div class="items-grid">
                <div class="item">
                  <h4>Asset pawning</h4>
                  <p>
                    Get cash loans for all assets to fund your business without
                    having to sell it through a secured short term loan.
                  </p>
                </div>
                <div class="item">
                  <h4>Invoice discounting & LPO Financing</h4>
                  <p>
                    Don’t miss an opportunity to meet your orders. Unlock cash
                    tied up in unpaid invoices to execute new contracts.
                  </p>
                </div>
                <div class="item">
                  <h4>Sales and Leaseback Loans</h4>
                  <p>
                    Invest more into your business for new equipment and other
                    business expansion opportunities using your asset while
                    still remaining in possession of the asset for the specified
                    term of the lease.
                  </p>
                </div>
                <div class="item">
                  <h4>Asset Financing</h4>
                  <p>
                    From vehicle to equipment, invest in new equipment or
                    upgrade by getting business loans to help with your
                    company's growth.
                  </p>
                </div>
                <div class="item">
                  <h4>Asset finance and Leases</h4>
                  <p>
                    Unlock the value of your vehicle without having to sell it
                    through a secured short term loan. Get cash loans for all
                    assets that are movable.{' '}
                  </p>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
      <div className="get-in-touch" id="get-in-touch">
        <div className="wrapper-gray">
          <div className="container">
            <Reveal>
              <FadeInRight>
                <h2>get in touch</h2>
              </FadeInRight>
            </Reveal>
          </div>
        </div>
        <div className="contact-wrapper">
          <div className="container">
            <div className="contact-section">
              <div className="contact-info">
                <Reveal>
                  <FadeInTop stagger={0.3}>
                    <p>
                      Feel free to get in contact with us,
                      <br />
                      we are here to help
                    </p>
                    <p>
                      e.
                      <br />
                      <b>info@credupng.com</b>
                    </p>
                    <p>
                      p.
                      <br />
                      <b>0701 476 2547 | 0701 064 8589</b>
                    </p>
                  </FadeInTop>
                </Reveal>
              </div>
              <div className="contact-form">
                <Reveal>
                  <FadeIn stagger={0.5}>
                    <h3>request a call back</h3>
                    <form class="cred-form">
                      <input type="text" name="name" placeholder="Name" />
                      <input
                        type="number"
                        name="phone"
                        placeholder="Phone number"
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Email address"
                      />
                      <textarea name="message" placeholder="Message"></textarea>
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </form>
                  </FadeIn>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="connect">
        <div class="container">
          <div class="newsletter">
            <Reveal>
              <FadeIn>
                <h4>
                  Stay updated, sign up for <br /> our newsletter
                </h4>
              </FadeIn>
              <FadeIn position="<=0.3">
                <form class="cred-form newsletter-form">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email address"
                  />
                  <button type="submit" class="submit-btn">
                    Submit
                  </button>
                </form>
              </FadeIn>
            </Reveal>
          </div>
          <Reveal>
            <div class="connect-with-us">
              <FadeIn>
                <h5>connect with us</h5>
              </FadeIn>
              <FadeInTop position="<=0.3">
                <a class="sm-link" href="https://linkedin.com">
                  linkedin.
                </a>
              </FadeInTop>
              <FadeInTop position="<=0.3">
                <a class="sm-link" href="https://facebook.com">
                  facebook.
                </a>
              </FadeInTop>
            </div>
          </Reveal>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
